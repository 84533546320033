import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { SanityBlock, SanityButton } from "../../../graphql-types";
import { LinkButtonColor, StyledButton } from "../styled/StyledButton";
import * as styles from "./homeBlocks.module.scss";
import { StyledLink } from "../styled/StyledLink";

interface Props {
  blocks: SanityBlock;
  centerDesktop?: boolean;
  centerButton?: boolean;
}

export const HomeBlocks: React.FC<Props> = ({
  blocks,
  centerDesktop = false,
  centerButton = false,
}) => {
  const serializers = {
    marks: {
      link: (props: any) => (
        <StyledLink
          path={props.mark.href}
          target={props.mark.target}
          color={props.mark?.color?.hex}
        >
          {props.children}
        </StyledLink>
      ),
      color: (props: any) => {
        return (
          <div
            style={{
              color: props.mark.hex,
              display: "inline",
            }}
          >
            {props.children}
          </div>
        );
      },
    },
    types: {
      button: (props: any) => {
        const button: SanityButton = props.node;
        return (
          <div
            className={`${centerButton ? styles.marginAuto : ""} ${
              styles.button
            }`}
          >
            <StyledButton button={button} color={LinkButtonColor.black} />
          </div>
        );
      },
    },
  };

  return (
    <div
      className={`${styles.wrapper} ${
        centerDesktop ? styles.centerDesktop : ""
      }`}
    >
      {blocks && (
        <BlockContent
          blocks={blocks}
          //   projectId={process.env.GATSBY_SANITY_PROJECT_ID || ""}
          //   dataset={process.env.GATSBY_SANITY_DATASET || "production"}
          serializers={serializers}
          //   className={styles.column}
        />
      )}
    </div>
  );
};

import { graphql, PageProps } from "gatsby";
import React, { useState } from "react";
import { usePageContext } from "../components/context/PageContext";
import { Layout } from "../components/layout/Layout";
import { PageImageHeader } from "../components/layout/PageImageHeader";
import { ToursPageQuery } from "../../graphql-types";
import { ContentSection } from "../components/section/ContentSection";
import * as styles from "../styles/pages/tours.module.scss";
import BlockContent from "@sanity/block-content-to-react";
import { GatsbyImage } from "gatsby-plugin-image";
import { Accordion, AccordionItem } from "../components/accordion/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { Map } from "../components/map/Map";
import { BreadCrumb } from "../components/breadcrumb/BreadCrumb";
import { SEO } from "../components/seo/seo";
import { LinkBlocks } from "../components/blocksContent/LinkBlocks";

interface Props {
  data: ToursPageQuery;
  location: any;
}

const ToursPage: React.FC<Props> = ({ data, location }) => {
  const { lang } = usePageContext();
  const { sanityToursPage } = data;
  const [selectedTransport, setSelectedTransport] = useState<number>(0);

  return (
    <>
      <SEO rawMeta={sanityToursPage?._rawMetadata} />
      <PageImageHeader
        image={sanityToursPage?.data?.bgImage?.image?.asset?.gatsbyImageData}
        title={sanityToursPage?.data?._rawTitle?.[lang]}
      />
      <BreadCrumb label={sanityToursPage?.data?._rawTitleBreadcrumb?.[lang]} />

      <ContentSection
        title={sanityToursPage?.data?._rawSubtitle?.[lang]}
        blocks={sanityToursPage?.data?._rawDescription?.[lang]}
      />
      <div className={styles.wrapper}>
        <div className={styles.itemsWrapper}>
          {sanityToursPage?.data?.sectionItems?.map(item => (
            <div className={styles.itemContainer} key={item?._key}>
              {/* <div>
              </div> */}
              <div>
                <h2>{item?._rawTitle?.[lang]}</h2>
                <BlockContent blocks={item?._rawText?.[lang]} />
              </div>
              <div className={styles.subItemWrapper}>
                {item?.subItems?.map(subItem => (
                  <div className={styles.subItem} key={subItem?._key}>
                    <div>
                      <GatsbyImage
                        image={subItem?.img?.image?.asset?.gatsbyImageData}
                        alt={subItem?.img?._rawAlt?.[lang] ?? "tours"}
                        className={styles.itemImageWrapper}
                      />
                    </div>
                    <div>
                      <h2>{subItem?._rawTitle?.[lang]}</h2>
                      <p>{subItem?._rawSubtitle?.[lang]}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div className={styles.transportWrapper}>
          <div className="desktopCentered">
            <h2>{sanityToursPage?.data?._rawTransportTitle?.[lang]}</h2>
            <LinkBlocks
              blocks={sanityToursPage?.data?._rawTransportDescription?.[lang]}
            />
          </div>
          <div className={styles.transportContainerMobile}>
            <Accordion>
              {sanityToursPage?.data?.transportItems?.map(
                (item, index: number) => (
                  <AccordionItem
                    title={item?._rawTitle?.[lang]}
                    key={`${index}-accordion`}
                    icon={<FontAwesomeIcon icon={faChevronDown} />}
                  >
                    <LinkBlocks blocks={item?._rawText?.[lang]} />

                    <div className={styles.cartoMobile}>
                      {item?.image?.image?.asset?.gatsbyImageData ? (
                        <GatsbyImage
                          alt={item?.image?._rawAlt?.[lang]}
                          image={item?.image?.image?.asset?.gatsbyImageData}
                        />
                      ) : (
                        <Map
                          round
                          // menu={size.width >= 1024}
                          OTS={[]}
                          blockCenterUserInit
                        />
                      )}
                    </div>
                  </AccordionItem>
                )
              )}
            </Accordion>
          </div>

          <div className={styles.transportContainerDesktop}>
            <div className={styles.transportButtonsWrapper}>
              {sanityToursPage?.data?.transportItems?.map(
                (item, index: number) => (
                  <div
                    className={`${
                      selectedTransport === index ? styles.selected : ""
                    }`}
                    key={`${item?._key}-title`}
                    onClick={() => setSelectedTransport(index)}
                  >
                    {item?._rawTitle?.[lang]}
                  </div>
                )
              )}
            </div>

            {sanityToursPage?.data?.transportItems?.map(
              (item, index: number) => (
                <div
                  className={`${styles.transportContent} ${
                    selectedTransport !== index ? styles.notDisplayed : ""
                  }`}
                  key={`${item?._key}-content`}
                >
                  <div className={styles.transportText}>
                    <LinkBlocks blocks={item?._rawText?.[lang]} />
                  </div>
                  <div className={styles.carto}>
                    {item?.image?.image?.asset?.gatsbyImageData ? (
                      <GatsbyImage
                        alt={item.image._rawAlt?.[lang]}
                        image={item.image.image.asset.gatsbyImageData}
                      />
                    ) : (
                      <Map
                        round
                        // menu={size.width >= 1024}
                        OTS={[]}
                        blockCenterUserInit
                      />
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ToursPage;

export const QUERY = graphql`
  query ToursPage {
    sanityToursPage {
      _rawMetadata(resolveReferences: { maxDepth: 10 })
      data {
        _rawTitleBreadcrumb
        _rawDescription
        _rawSubtitle
        _rawTitle
        bgImage {
          image {
            asset {
              gatsbyImageData
            }
          }
        }
        sectionItems {
          _key
          _rawTitle
          _rawText
          subItems {
            _key
            _rawSubtitle
            _rawTitle
            img {
              image {
                asset {
                  gatsbyImageData
                }
              }
              _rawAlt
            }
          }
        }
        _rawTransportTitle
        _rawTransportDescription
        transportItems {
          _key
          _rawTitle
          _rawText
          image {
            _rawAlt
            image {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`;

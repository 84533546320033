import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import { SanityBlock } from "../../../graphql-types";
import { StyledLink } from "../styled/StyledLink";
import * as styles from "./linkBlocks.module.scss";

interface Props {
  blocks: SanityBlock;
  centerDesktop?: boolean;
  centerButton?: boolean;
}

export const LinkBlocks: React.FC<Props> = ({ blocks }) => {
  const serializers = {
    marks: {
      link: (props: any) => (
        <StyledLink
          path={props.mark.href}
          target={props.mark.target}
          className={styles.link}
          color={props.mark?.color?.hex}
        >
          {props.children}
        </StyledLink>
      ),
    },
  };

  return (
    <div className={styles.wrapper}>
      {blocks && (
        <BlockContent
          blocks={blocks}
          //   projectId={process.env.GATSBY_SANITY_PROJECT_ID || ""}
          //   dataset={process.env.GATSBY_SANITY_DATASET || "production"}
          serializers={serializers}
          //   className={styles.column}
        />
      )}
    </div>
  );
};

import React from "react";
import { SanityBlock } from "../../../graphql-types";
import * as styles from "./contentSection.module.scss";
import { HomeBlocks } from "../blocksContent/HomeBlocks";

interface Props {
  title: string;
  blocks: SanityBlock;
}

export const ContentSection: React.FC<Props> = ({ title, blocks }) => {
  return (
    <section className={styles.wrapper}>
      <h2>{title}</h2>
      <HomeBlocks blocks={blocks} />
    </section>
  );
};
